<template>
    <div class="wrap">
        <el-page-header @back="$router.back()" content="活动详情" class="margin-bottom margin-top-xs"></el-page-header>
        <div class="form_box">
            <div class="subItem">
                <div class="itemize">基本信息</div>
                <div class="row">
                    <div class="col">
                        <div class="col-label">订单号：</div>
                        <div class="col-value">{{ form.order_no }}</div>
                    </div>
                    <div class="col">
                        <div class="col-label">订单类型：</div>
                        <div class="col-value">
                            <el-button type="primary" size="mini" plain v-if="form.type === 1">活动订单</el-button>
                            <el-button type="warning" size="mini" plain v-else-if="form.type === 2">商品订单</el-button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="col-label">订单创建人：</div>
                        <div class="col-value">{{ form.nickname }}</div>
                    </div>
                    <div class="col">
                        <div class="col-label">下单时间：</div>
                        <div class="col-value">
                            {{ $util.date.format(form.created_at, 'yyyy-MM-dd hh:mm:ss') }}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="col-label">活动名称：</div>
                        <div class="col-value">{{ form.type === 1 ? form.activity_name : form.goods_name }}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="col-label">买家：</div>
                        <div class="col-value">{{ form.buyer_name }}</div>
                    </div>
                    <div class="col">
                        <div class="col-label">买家电话：</div>
                        <div class="col-value">{{ form.buyer_mobile }}</div>
                    </div>
                    <div class="col">
                        <div class="col-label">剩余核销次数：</div>
                        <div class="col-value">{{ form.last_check_num }}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="col-label">售价：</div>
                        <div class="col-value">{{ `￥${form.pay_money}` }}</div>
                    </div>
                    <div class="col">
                        <div class="col-label">支付状态：</div>
                        <div class="col-value">
                            <el-button type="success" size="mini" plain v-if="form.pay_status === 2">已支付</el-button>
                            <el-button type="danger" size="mini" plain v-else-if="form.pay_status === 1">未支付</el-button>
                        </div>
                    </div>
                    <div class="col">
                        <div class="col-label">支付时间：</div>
                        <div class="col-value">{{ form.pay_status === 2 ? $util.date.format(form.pay_time * 1000, 'yyyy-MM-dd hh:mm:ss') : '-' }}</div>
                    </div>
                </div>
            </div>
            <div class="subItem">
                <div class="itemize">抽奖数据</div>
                <div class="row">
                    <div class="col">
                        <div class="col-label">赠送抽奖次数：</div>
                        <div class="col-value">{{ statistic.give_awards_num }}</div>
                    </div>
                    <div class="col">
                        <div class="col-label">分享抽奖次数：</div>
                        <div class="col-value">
                            <div class="col-value">{{ statistic.share_awards_num }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="subItem">
                <div class="itemize">中奖记录</div>
                <el-table :data="awardRecord" v-loading="loading">
                    <el-table-column type="index" width="50" />
                    <el-table-column label="中奖人" prop="buyer_name" />
                    <el-table-column label="联系电话" prop="buyer_mobile" />
                    <el-table-column label="中奖商品" prop="goods_name" />
                    <el-table-column label="商品海报">
                        <template slot-scope="scope">
                            <el-image style="width: 50px; height: 50px" :src="scope.row.goods_image" :preview-src-list="[scope.row.goods_image]" />
                        </template>
                    </el-table-column>
                    <el-table-column label="关联商户" prop="merchant_name" />
                    <el-table-column label="核销状态" align="center">
                        <template slot-scope="scope">
                            <el-button type="success" size="mini" plain v-if="scope.row.check_status === 2">已核销</el-button>
                            <el-button type="danger" size="mini" plain v-else>未核销</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="核销码" prop="check_code" />
                </el-table>
                <div class="text-center margin-top">
                    <el-pagination
                        @current-change="getAwardRecord"
                        :current-page="paramAward.page"
                        :page-size="paramAward.page_size"
                        layout="prev, pager, next, total"
                        :total="paramAward.total"
                    ></el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            form: {},
            statistic: {},
            shareRecord: [],
            checkRecord: [],
            awardRecord: [],
            paramShare: {
                order_id: this.$route.params.id,
                page: 1,
                total: 0,
                page_size: 0,
            },
            paramCheck: {
                order_id: this.$route.params.id,
                page: 1,
                total: 0,
                page_size: 0,
            },
            paramAward: {
                order_id: this.$route.params.id,
                page: 1,
                total: 0,
                page_size: 0,
            },
            loading: false,
        };
    },
    created() {
        this.getDetail();
        this.getStatistics();
        this.getShareRecord(1);
        this.getAwardRecord(1);
    },
    methods: {
        getDetail: function() {
            this.$api.platformActivity.getOrderDetail({ id: this.$route.params.id }).then((res) => {
                this.form = res.data;
            });
        },
        getStatistics: function() {
            this.$api.platformActivity.getStatistics({ order_id: this.$route.params.id }).then((res) => {
                this.statistic = res.data || {};
            });
        },
        getShareRecord: function(page) {
            this.paramShare.page = page;
            this.$api.platformActivity.getShareRecord(this.paramShare).then((res) => {
                this.shareRecord = res.data.data;
                this.paramShare.total = res.data.total;
                this.paramShare.page_size = res.data.per_page;
                this.loading = false;
            });
        },
        getAwardRecord: function(page) {
            this.paramAward.page = page;
            this.$api.platformActivity.getAwardRecord(this.paramAward).then((res) => {
                this.awardRecord = res.data.data;
                this.paramAward.total = res.data.total;
                this.paramAward.page_size = res.data.per_page;
                this.loading = false;
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.form_box {
    .el-input,
    .el-textarea {
        width: 100%;
        max-width: 500px;
    }
}
.subItem {
    padding-left: 120px;
    padding-top: 60px;
    position: relative;

    .itemize {
        font-size: 16px;
        color: #333;
        padding-left: 20px;
        position: absolute;
        top: 0;
        left: 0;
    }

    .itemize:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        width: 10px;
        height: 10px;
        background-color: #409eff;
    }
}

.goods-list {
    display: flex;
    margin-bottom: 20px;

    .goods-card {
        margin-right: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 200px;
        box-shadow: 0 4px 16px 2px rgba(113, 128, 193, 0.15);
        transform: scale(1);
        transition: all 0.3s ease;
        padding-bottom: 10px;
        position: relative;

        &:hover {
            transform: scale(1.02);
        }

        .goods-name {
            margin: 10px;
            font-size: 14px;
            font-weight: 500;
            color: #999;
        }

        .goods-card-progress {
            width: 100%;
            box-sizing: border-box;
            padding-left: 10px;
            padding-bottom: 10px;
        }
    }
}

.row {
    margin-bottom: 22px;
    display: flex;
    font-size: 15px;
    font-weight: 400;
    color: #333;
    line-height: 28px;

    .col {
        display: inline-flex;

        .col-label {
            width: 120px;
            text-align: right;
        }

        .col-value {
            margin-left: 8px;
            margin-right: 20px;
            width: 260px;
        }
    }
}
</style>
